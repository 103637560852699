import React from "react";
import Seo from "components/Seo";
import { StaticImage } from "gatsby-plugin-image";
import { VStack, Box, Text } from "@chakra-ui/react";
import Container from "components/ui/Container";

const FormSubmitSuccessPage = () => (
  <Box py={10}>
    <Seo title="Form Submitted Successfully" />

    <Container fluid>
      <VStack
        mx="auto"
        maxW="600px"
        spacing={10}
        alignItems="center"
        textAlign="center"
      >
        <StaticImage src="../images/banners/success.png" alt="form submitted success image" />

        <Text as="h1" fontSize="5xl" fontWeight="light">
          Form submitted successfully
        </Text>

        <Text fontSize="2xl" fontWeight="light">
          One of our executive will connect with you shortly.
        </Text>
      </VStack>
    </Container>
  </Box>
);

export default FormSubmitSuccessPage;
